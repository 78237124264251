import React from "react";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
const HowToItem = ({ key, img, heading, desc }) => {
  return (
    <div key={key} className="item">
      <img src={img} className="img-fluid" alt="apply" />

      <div className="content">
        <h3>{heading}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default HowToItem;
