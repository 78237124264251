export const monthlyPackage = [
  {
    packageName: "Trial",
    desc: "Ideal to try out Leadbud.io sales pack.",
    price: "0.00",
    package: [
      {
        check: true,
        value: "150 credits",
      },
      {
        check: false,
        value: "100 email recipients",
      },
      {
        check: true,
        value: "Unlimited sender accounts",
      },
      {
        check: true,
        value: "1 mailbox warm-up",
      },
    ],
    whatsIncluded: [
      {
        check: true,
        value: "Email Finder",
      },
      {
        check: false,
        value: "Email Verifier",
      },
      {
        check: true,
        value: "Email Warm-up ",
      },
      {
        check: false,
        value: "Sales CRM",
      },
      {
        check: true,
        value: "Drip Campaigns",
      },
    ],
  },
  {
    packageName: "Starter",
    desc: "All the core tools needed to set up steady sales.",
    price: "30.00",
    package: [
      {
        check: true,
        value: "150 credits",
      },
      {
        check: false,
        value: "100 email recipients",
      },
      {
        check: true,
        value: "Unlimited sender accounts",
      },
      {
        check: true,
        value: "1 mailbox warm-up",
      },
    ],
    whatsIncluded: [
      {
        check: true,
        value: "Email Finder",
      },
      {
        check: false,
        value: "Email Verifier",
      },
      {
        check: true,
        value: "Email Warm-up ",
      },
      {
        check: false,
        value: "Sales CRM",
      },
      {
        check: true,
        value: "Drip Campaigns",
      },
    ],
  },
  {
    packageName: "Pro",
    desc: "Pro choice for unlimited business opportunities.",
    price: "75.00",
    package: [
      {
        check: true,
        value: "150 credits",
      },
      {
        check: false,
        value: "100 email recipients",
      },
      {
        check: true,
        value: "Unlimited sender accounts",
      },
      {
        check: true,
        value: "1 mailbox warm-up",
      },
    ],
    whatsIncluded: [
      {
        check: true,
        value: "Email Finder",
      },
      {
        check: false,
        value: "Email Verifier",
      },
      {
        check: true,
        value: "Email Warm-up ",
      },
      {
        check: false,
        value: "Sales CRM",
      },
      {
        check: true,
        value: "Drip Campaigns",
      },
    ],
  },
  {
    packageName: "Team",
    desc: "Scale your growth strategy within your team.",
    price: "110.00",
    package: [
      {
        check: true,
        value: "150 credits",
      },
      {
        check: false,
        value: "100 email recipients",
      },
      {
        check: true,
        value: "Unlimited sender accounts",
      },
      {
        check: true,
        value: "1 mailbox warm-up",
      },
    ],
    whatsIncluded: [
      {
        check: true,
        value: "Email Finder",
      },
      {
        check: false,
        value: "Email Verifier",
      },
      {
        check: true,
        value: "Email Warm-up ",
      },
      {
        check: false,
        value: "Sales CRM",
      },
      {
        check: true,
        value: "Drip Campaigns",
      },
    ],
  },
];
export const yearlyPackage = [
  {
    packageName: "1 year",
    desc: "Ideal to try out Leadbud.io sales pack.",
    price: "0.00",
    package: [
      {
        check: true,
        value: "150 credits",
      },
      {
        check: false,
        value: "100 email recipients",
      },
      {
        check: true,
        value: "Unlimited sender accounts",
      },
      {
        check: true,
        value: "1 mailbox warm-up",
      },
    ],
    whatsIncluded: [
      {
        check: true,
        value: "Email Finder",
      },
      {
        check: false,
        value: "Email Verifier",
      },
      {
        check: true,
        value: "Email Warm-up ",
      },
      {
        check: false,
        value: "Sales CRM",
      },
      {
        check: true,
        value: "Drip Campaigns",
      },
    ],
  },
  {
    packageName: "2 Year",
    desc: "All the core tools needed to set up steady sales.",
    price: "30.00",
    package: [
      {
        check: true,
        value: "150 credits",
      },
      {
        check: false,
        value: "100 email recipients",
      },
      {
        check: true,
        value: "Unlimited sender accounts",
      },
      {
        check: true,
        value: "1 mailbox warm-up",
      },
    ],
    whatsIncluded: [
      {
        check: true,
        value: "Email Finder",
      },
      {
        check: false,
        value: "Email Verifier",
      },
      {
        check: true,
        value: "Email Warm-up ",
      },
      {
        check: false,
        value: "Sales CRM",
      },
      {
        check: true,
        value: "Drip Campaigns",
      },
    ],
  },
  {
    packageName: "3 Year",
    desc: "Pro choice for unlimited business opportunities.",
    price: "75.00",
    package: [
      {
        check: true,
        value: "150 credits",
      },
      {
        check: false,
        value: "100 email recipients",
      },
      {
        check: true,
        value: "Unlimited sender accounts",
      },
      {
        check: true,
        value: "1 mailbox warm-up",
      },
    ],
    whatsIncluded: [
      {
        check: true,
        value: "Email Finder",
      },
      {
        check: false,
        value: "Email Verifier",
      },
      {
        check: true,
        value: "Email Warm-up ",
      },
      {
        check: false,
        value: "Sales CRM",
      },
      {
        check: true,
        value: "Drip Campaigns",
      },
    ],
  },
  {
    packageName: "4 Year",
    desc: "Scale your growth strategy within your team.",
    price: "110.00",
    package: [
      {
        check: true,
        value: "150 credits",
      },
      {
        check: false,
        value: "100 email recipients",
      },
      {
        check: true,
        value: "Unlimited sender accounts",
      },
      {
        check: true,
        value: "1 mailbox warm-up",
      },
    ],
    whatsIncluded: [
      {
        check: true,
        value: "Email Finder",
      },
      {
        check: false,
        value: "Email Verifier",
      },
      {
        check: true,
        value: "Email Warm-up ",
      },
      {
        check: false,
        value: "Sales CRM",
      },
      {
        check: true,
        value: "Drip Campaigns",
      },
    ],
  },
];
