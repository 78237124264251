import React from "react";

export default function Para1(props) {
  return (
    <div
      className="text-[12px] md:text-[15px] text-para font-normal"
      style={{ fontSize: "15px", color: "rgb(123,123,123)" }}
    >
      {props.para1}
    </div>
  );
}
