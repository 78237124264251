import React from "react";
import { Link } from "react-router-dom";
import applyIcon from "../../../../assets/img/affiliate/apply-icon.png";
import promoteIcon from "../../../../assets/img/affiliate/promote-icon.png";
import growIcon from "../../../../assets/img/affiliate/grow-icon.png";
import HowToItem from "../../../../components/howToItem/howToItem";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const HowTo = () => {
  const data = [
    {
      img: applyIcon,
      heading: "Apply",
      desc: "Apply for the program and wait for your account approval by a Customer Care.",
    },
    {
      img: promoteIcon,
      heading: "Promote",
      desc: "hare your personal affiliate link with your audience, create Leadbud.io-related content.",
    },
    {
      img: growIcon,
      heading: "Grow Your Passive Income",
      desc: "Receive 25% from each referral's order for up to 12 months of renewals.",
    },
  ];

  return (
    <div className="section how-to">
      <div className="container">
        <div className="content-area text-center">
          <h2>
            How Can I Become
            <br />A Leadbud.io Affiliate Partner?
          </h2>
        </div>

        <div className="items-area">
          {data.map((item, i) => (
            <HowToItem
              key={i}
              img={item.img}
              heading={item.heading}
              desc={item.desc}
            />
          ))}
        </div>

        <div className="button-area text-center">
          <Link to={"/"} className="btn btn-apply">
            Apply Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HowTo;
