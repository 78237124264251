import React from "react";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";

const HomeFaqs = () => {
  return (
    <div className="section home-faqs">
      <div className="container">
        <div className="content">
          <div className="content-area text-center">
            <h2 className="aos-item aos-inint aos-animate">
              Frequently Asked Questions?
            </h2>
            <div className="faq-para-wrapper">
              <p className="aos-item aos-inint aos-animate">
                Candid Answers To Common Queries: Get Quick Insights Into
                Leadbud's Functionality,Security, And Benefits With Our
                Frequently Asked Questions.
              </p>
            </div>
          </div>

          <div className="faqs-area" style={{ marginTop: "20px" }}>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item aos-item aos-inint aos-animate">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    1. What Is Leadbud, And How Can It Benefit My Business?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse "
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Leadbud Is A Comprehensive Lead Generation Tool Designed
                      To Help Businesses Capture, Nurture, And Convert Leads
                      Effectively. It Benefits Your Business By Simplifying Lead
                      Generation Processes, Automating Tasks, And Providing
                      Valuable Insights To Boost Your Sales And Growth.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item aos-item aos-inint aos-animate">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    2. Is Leadbud Suitable For My Industry Or Business Size?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Leadbud Is A Comprehensive Lead Generation Tool Designed
                      To Help Businesses Capture, Nurture, And Convert Leads
                      Effectively. It Benefits Your Business By Simplifying Lead
                      Generation Processes, Automating Tasks, And Providing
                      Valuable Insights To Boost Your Sales And Growth.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item aos-item aos-inint aos-animate">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    3. Do I Need Technical Skills To Use Leadbud?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Leadbud Is A Comprehensive Lead Generation Tool Designed
                      To Help Businesses Capture, Nurture, And Convert Leads
                      Effectively. It Benefits Your Business By Simplifying Lead
                      Generation Processes, Automating Tasks, And Providing
                      Valuable Insights To Boost Your Sales And Growth.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item aos-item aos-inint aos-animate">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    4. Can I Try Leadbud Before Purchasing A Subscription?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Leadbud Is A Comprehensive Lead Generation Tool Designed
                      To Help Businesses Capture, Nurture, And Convert Leads
                      Effectively. It Benefits Your Business By Simplifying Lead
                      Generation Processes, Automating Tasks, And Providing
                      Valuable Insights To Boost Your Sales And Growth.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item aos-item aos-inint aos-animate">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    5. How Can I Upgrade Or Change My Leadbud Subscription Plan?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Leadbud Is A Comprehensive Lead Generation Tool Designed
                      To Help Businesses Capture, Nurture, And Convert Leads
                      Effectively. It Benefits Your Business By Simplifying Lead
                      Generation Processes, Automating Tasks, And Providing
                      Valuable Insights To Boost Your Sales And Growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFaqs;
