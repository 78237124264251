import React from "react";
import BlogTitle from "./components/blogTitle/blogTitle";
import HomeBlog from "./components/homeBlog/homeBlog";

const Blog = () => {
  return (
    <div className="main-body">
      <BlogTitle />
      <HomeBlog />
    </div>
  );
};

export default Blog;
