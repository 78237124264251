import React from "react";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const TermsAndConditionTitle = () => {
  return (
    <div className="pricing-title-area">
      <div className="container">
        <div className="content-area text-center">
          <h2>Trust Meets Transparency: Our Terms Simplified</h2>
          <h1>
            <span>LeadBud.io</span> Terms & Conditions
          </h1>

          <p>
            Our Terms & Conditions offer clarity and trust, ensuring a seamless
            <br />
            experience for every user
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionTitle;
