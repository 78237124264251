import React from "react";
import dollarIcon from "../../../../assets/img/pricing/dollar-icon.png";
import handIcon from "../../../../assets/img/pricing/hand-icon.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const PricingTitle = () => {
  return (
    <div className="pricing-title-area">
      <div className="container">
        <div className="content-area text-center">
          <h2>Bratislav Bojic, Chief Operating Officer at Product Hive</h2>
          <h1>
            <span>Don’t hesitate.</span> It’s just the thing
          </h1>

          <p>
            Transform how you work with Leadbud.io.
            <br />
            Sign up to start for free and go pro when you're ready.
          </p>

          <div className="image-area">
            <img
              src={dollarIcon}
              className="img-fluid dollar"
              alt="dollar-icon"
            />
            <img src={handIcon} className="img-fluid hand" alt="hand-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTitle;
