import React from "react";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
const BlogItem = ({ key, image, date, heading, desc }) => {
  return (
    <div key={key} className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
      <div className="item">
        <img src={image} className="img-fluid" alt="blog" />

        <p className="date">{date}</p>
        <h3>{heading}</h3>
        <div className="content">
          <p>{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
