import React from "react";
import ContactUsTitle from "./components/contactUsTitle/contactUsTitle";
import ContactAndContact from "./components/contactAndContact/contactAndContact";

const ContactUs = () => {
  return (
    <div className="main-body">
      <ContactUsTitle />
      <ContactAndContact />
    </div>
  );
};

export default ContactUs;
