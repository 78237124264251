import React, { useState } from "react";
import logo from "../../assets/img/logo.svg";
import { IoReorderThreeOutline, IoCloseSharp } from "react-icons/io5";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { leadbud, solution, pages } from "./navBarData";
import { FaChevronDown } from "react-icons/fa";

const MobileHeader = () => {
  const [open, setOpen] = useState(false);
  const [isLeadbudMenuVisible, setIsLeadbudMenuVisible] = useState(false);
  const [isSolutionMenuVisible, setIsSolutionMenuVisible] = useState(false);
  const [isPagesMenuVisible, setIsPagesMenuVisible] = useState(false);

  const toggleLeadbudMenu = () => {
    setIsLeadbudMenuVisible(!isLeadbudMenuVisible);
  };

  const toggleSolutionMenu = () => {
    setIsSolutionMenuVisible(!isSolutionMenuVisible);
  };

  const togglePagesMenu = () => {
    setIsPagesMenuVisible(!isPagesMenuVisible);
  };
  return (
    <div className={`bg-bgmain ${open ? "sticky-header" : ""}`}>
      <div
        className="mobile-navbar-menu-container container "
        style={{ padding: "12px 32px" }}
      >
        <div
          className="nav-bar-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="mobile-brand">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "177px", height: "auto" }}
            />
          </div>
          <div onClick={() => setOpen(!open)} style={{ fontSize: "30px" }}>
            {open ? <IoCloseSharp /> : <IoReorderThreeOutline />}
          </div>
        </div>

        {open ? (
          <div className="mobile-navbar-links-container">
            <div
              className="btn-links-wrapper container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "0px",
                }}
              >
                <li
                  className="mobile-dropdown-menu-wrapper  why-leadbud"
                  onClick={toggleLeadbudMenu}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                  >
                    <Link className="mobile-dropdown-menu-title">
                      Why leadbud
                    </Link>
                    <FaChevronDown />
                  </div>
                  {isLeadbudMenuVisible ? (
                    <div className="leadbud-menu ">
                      <ul
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          paddingLeft: 0,
                        }}
                      >
                        {leadbud.map((value, i) => (
                          <li
                            className="dropDown-mobile-item"
                            style={{ paddingLeft: "0px" }}
                            onClick={() => setOpen(false)}
                          >
                            <Link
                              to={value.link}
                              key={i}
                              style={{
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                color: "#000",
                                display: "block",
                              }}
                            >
                              {value.heading}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </li>
                <li
                  className="mobile-dropdown-menu-wrapper solution-head"
                  onClick={toggleSolutionMenu}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                  >
                    <Link className="mobile-dropdown-menu-title">Solution</Link>
                    <FaChevronDown />
                  </div>
                  {isSolutionMenuVisible ? (
                    <div className="solution-menu ">
                      <ul
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          paddingLeft: 0,
                        }}
                      >
                        {solution.map((value, i) => (
                          <li
                            className="dropDown-mobile-item"
                            style={{ paddingLeft: "0px" }}
                            onClick={() => setOpen(false)}
                          >
                            <Link
                              to={value.link}
                              key={i}
                              style={{
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                color: "#000",
                                display: "block",
                              }}
                            >
                              {value.mediaheading}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </li>
                <li
                  className="mobile-dropdown-menu-wrapper pages"
                  onClick={togglePagesMenu}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                  >
                    <Link className="mobile-dropdown-menu-title">Pages</Link>
                    <FaChevronDown />
                  </div>

                  {isPagesMenuVisible ? (
                    <div className="pages-menu ">
                      <ul
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          paddingLeft: 0,
                        }}
                      >
                        {pages.map((value, i) => (
                          <li
                            className="dropDown-mobile-item"
                            style={{ paddingLeft: "0px" }}
                            onClick={() => setOpen(false)}
                          >
                            <Link
                              to={value.link}
                              key={i}
                              style={{
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                color: "#000",
                                display: "block",
                              }}
                            >
                              {value.mediaheading}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </li>
                <li className="mobile-dropdown-menu-wrapper">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                  >
                    <Link className="mobile-dropdown-menu-title">
                      Resources
                    </Link>
                  </div>
                </li>
                <li className="mobile-dropdown-menu-wrapper">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                  >
                    <Link className="mobile-dropdown-menu-title">Placing</Link>
                  </div>
                </li>
              </ul>
              <div
                className="header-btns"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  marginTop: "auto",
                }}
              >
                <Link to="/" className="btn">
                  Book a Call
                </Link>
                <Link to="/" className="btn">
                  SignUp
                </Link>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MobileHeader;
