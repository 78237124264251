import React from "react";
import platformCapabilities from "../../../../assets/img/platform-capabilities.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";

const HomePlatformCapabilities = () => {
  return (
    <div className="section home-platform-capabilities">
      <div className="container">
        <div className="content-area">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="content">
                <h2 className="aos-item aos-inint aos-animate">
                  Platfrom Capabilities
                </h2>
                <p className="aos-item aos-inint aos-animate">
                  LeadBud offers a range of powerful capabilities designed to
                  elevate your lead generation efforts:
                </p>

                <ul>
                  <li className="aos-item aos-inint aos-animate">
                    No Programming Experience Needed
                  </li>
                  <li className="aos-item aos-inint aos-animate">
                    Designed With Teamwork
                  </li>
                  <li className="aos-item aos-inint aos-animate">
                    Download Your Data In Preferred Formats
                  </li>
                  <li className="aos-item aos-inint aos-animate">
                    Seamless Workflow Integration
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="image-area float-end aos-item aos-inint aos-animate">
                <img
                  src={platformCapabilities}
                  className="img-fluid"
                  alt="platform-capabilities"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePlatformCapabilities;
