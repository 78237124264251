import React from "react";
import { Link } from "react-router-dom";
import SupportIcon from "../../../../assets/img/support-icon.png";
import flexibleBusinessIcon from "../../../../assets/img/flexible-business-icon.png";
import pricingIcon from "../../../../assets/img/pricing-icon.png";
import claimRewardButton from "../../../../assets/img/claim-reward-button.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const MoreSaasTools = () => {
  return (
    <div className="section more-saas-tool">
      <div className="container">
        <div className="content-area text-center">
          <div className="upper aos-item aos-inint aos-animate">
            <h2>Leadbud Is More Than A Saas Tool</h2>
            <p>
              LeadBud is more than just a SaaS tool. It's your growth partner,
              your ticket to expanding
              <br />
              your business. With LeadBud
            </p>
          </div>

          <div className="items">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-item">
                <div className="item aos-item aos-inint aos-animate">
                  <img
                    src={SupportIcon}
                    className="img-fluid"
                    alt="support-icon"
                  />
                  <p>24/7 Support</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-item">
                <div className="item aos-item aos-inint aos-animate">
                  <img
                    src={flexibleBusinessIcon}
                    className="img-fluid"
                    alt="flexible-business"
                  />
                  <p>Flexible Business Solution</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-item">
                <div className="item aos-item aos-inint aos-animate">
                  <img src={pricingIcon} className="img-fluid" alt="pricing" />
                  <p>Flexible Pricing</p>
                </div>
              </div>

              <div className="button-area aos-item aos-inint aos-animate">
                <Link to={"/"}>
                  <img
                    src={claimRewardButton}
                    className="img-fluid"
                    alt="button"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreSaasTools;
