import React from "react";
import logo1 from "../../../../assets/img/pricing/logo-1.png";
import logo2 from "../../../../assets/img/pricing/logo-2.png";
import logo3 from "../../../../assets/img/pricing/logo-3.png";
import logo4 from "../../../../assets/img/pricing/logo-4.png";
import logo5 from "../../../../assets/img/pricing/logo-5.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const Excellence = () => {
  return (
    <>
      <div className="section excellence">
        <div className="container">
          <div className="content text-center">
            <h2>
              Leadbud: Where Excellence
              <br />
              Meets Recognition
            </h2>
          </div>

          <div className="items-area">
            <div className="item">
              <img src={logo1} className="img-fluid" alt="logo" />
            </div>

            <div className="item">
              <img src={logo2} className="img-fluid" alt="logo" />
            </div>

            <div className="item">
              <img src={logo3} className="img-fluid" alt="logo" />
            </div>

            <div className="item">
              <img src={logo4} className="img-fluid" alt="logo" />
            </div>

            <div className="item">
              <img src={logo5} className="img-fluid" alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Excellence;
