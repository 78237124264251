import React from "react";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const TermsAndConditionContent = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <p className="date">Last Updated: 02/07/2022</p>

        <h3>1. Introduction</h3>
        <p>
          Welcome to LeadBud.io. These Terms & Conditions govern your use of our
          platform and services. By accessing or using our website, you agree to
          abide by these terms.
        </p>

        <h3>2. Services Description</h3>
        <p>
          LeadBud.io provides digital growth tools and resources. While we
          strive for accuracy and efficiency, we cannot guarantee uninterrupted
          service or specific outcomes.
        </p>

        <h3>3. Registration And User Accounts</h3>
        <p>
          To access certain features, users may need to register. Users are
          responsible for safeguarding their account information and for any
          activities under their account.
        </p>

        <h3>4. User Obligations</h3>

        <p>
          Users agree not to misuse the service or assist anyone else in doing
          so. This includes not interfering with our services or trying to
          access them using a method other than the interface and instructions
          we provide.
        </p>

        <h3>5. Intellectual Property</h3>

        <p>
          All content on LeadBud.io is the property of LeadBud.io and is
          protected by copyright, trademark, and other laws.
        </p>

        <h3>6. Payment And Refunds</h3>

        <p>
          Details regarding our pricing, payment, and refund policies are
          available on our Pricing page. All transactions are secure and
          encrypted.
        </p>

        <h3>7. Limitation Of Liability</h3>

        <p>
          LeadBud.io shall not be liable for any indirect, incidental, or
          consequential damages or losses. Our total liability shall not exceed
          the amount paid by the user.
        </p>

        <h3>8. Privacy Policy</h3>

        <p>
          Our Privacy Policy, incorporated by reference into these T&C,
          describes how we handle the data you provide to us or that we collect
          in connection with your use of LeadBud.io.
        </p>

        <h3>9. Termination</h3>
        <p>
          We reserve the right to suspend or terminate your access to our
          services if you violate these T&C or if we suspect malicious activity
          from your account.
        </p>

        <h3>10. Changes To T&C</h3>
        <p>
          LeadBud.io may periodically update these terms. The latest version
          will always be available on our site. Continued use of our services
          after changes constitutes acceptance.
        </p>

        <h3>11. Governing Law & Jurisdiction</h3>

        <p>
          These terms are governed by the laws of [Your Jurisdiction]. Disputes
          arising from these terms will be exclusively subject to the
          jurisdiction of courts in [Your Jurisdiction].
        </p>

        <h3>12. Contact</h3>

        <p>
          For any queries regarding these terms, please contact us at{" "}
          <a href="mailto:info@leadbud.io">info@leadbud.io</a>.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditionContent;
