import React from "react";
import AffiliateProgramTitle from "./components/affiliateProgramTitle/affiliateProgramTitle";
import HowTo from "./components/howTo/howTo";
import WhoCanPartner from "./components/whoCanPartner/whoCanPartner";
import WhyPopular from "./components/whyPopular/whyPopular";
import HowLeadbudsWoks from "../home/components/howLeadbudWorks/howLeadbudWorks";
import ReadyToSell from "../pricing/components/readyToSell/readyToSell";
import HomeTestimonial from "../home/components/homeTestimonial/homeTestimonial";
import HomeFaqs from "../home/components/homeFaqs/homeFaqs";
import Excellence from "../pricing/components/excellence/excellence";

const AffiliateProgram = () => {
  return (
    <div className="main-body">
      <AffiliateProgramTitle />
      <HowTo />
      <WhoCanPartner />
      <WhyPopular />
      <HowLeadbudsWoks />
      <ReadyToSell
        heading={"Create Account"}
        heading1={"To Become An Affiliate"}
      />
      <HomeTestimonial />
      <HomeFaqs />
      <Excellence />
    </div>
  );
};

export default AffiliateProgram;
