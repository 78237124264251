import React from "react";
import PrivacyPolicyTitle from "./components/privacyPolicyTitle/privacyPolicyTitle";
import PrivacyPolicyContent from "./components/privacyPolicyContent/privacyPolicyContent";

const PrivacyPloicy = () => {
  return (
    <div className="main-body">
      <PrivacyPolicyTitle />
      <PrivacyPolicyContent />
    </div>
  );
};

export default PrivacyPloicy;
