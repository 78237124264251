import React from "react";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const PrivacyPolicyContent = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <p className="date">Last Updated: 02/07/2022</p>

        <h3>Introduction:</h3>

        <p>
          At LeadBud.io, we recognize the importance of your privacy. We've
          created this Privacy Policy to demonstrate our ongoing commitment to
          protecting the personal information you provide while using our
          platform and services.
        </p>

        <h3>Data Collection:</h3>

        <h4>1. Personal Information:</h4>

        <p>
          When registering or making a purchase on LeadBud.io, you may be asked
          to provide your name, email address, payment details, and other
          pertinent information.
        </p>

        <h4>2. Usage Data:</h4>

        <p>
          We may also collect information on how our service is accessed and
          used. This may include your IP address, browser type, and other
          session data.
        </p>

        <h3>How We Use Your Information:</h3>

        <h4>1. Service Delivery:</h4>

        <p>
          {" "}
          Your details help us offer the personalized experiences and features
          you've come to expect from LeadBud.io.
        </p>

        <h4>2. Communication:</h4>

        <p>
          We may use your email to send newsletters, marketing campaigns,
          updates, and other service-related announcements.
        </p>

        <h4>3. Improvement & Analysis:</h4>

        <p>
          Usage data assists us in enhancing user experience, troubleshooting
          issues, and refining our tools and offerings.
        </p>

        <h3>Data Sharing And Disclosure:</h3>

        <p>
          Your privacy is of the utmost importance. We never sell or rent your
          personal information. However, we might share data with trusted third
          parties in the following scenarios:
        </p>

        <h4>Compliance with Laws:</h4>
        <p>
          We may disclose your information where required by law or legal
          proceedings.
        </p>

        <h4>Service Providers:</h4>
        <p>
          Third parties, such as payment gateways, may require access to your
          data to perform specific tasks on our behalf.
        </p>
        <p>
          They're obligated not to disclose or use your data for any other
          purpose.
        </p>

        <h3>Cookies:</h3>

        <p>
          We use cookies to enhance user experience, gather data about site
          traffic, and facilitate various site features. You can instruct your
          browser to reject cookies or to notify you when a cookie is being
          sent.
        </p>

        <h3>Data Security:</h3>

        <p>
          We employ a range of security measures to ensure the safety of your
          personal information, including encryption and secure servers.
          However, no online transmission can be guaranteed to be 100% secure.
          We strive to protect your data but cannot ensure or warrant its
          absolute security.
        </p>

        <h3>Your Choices:</h3>
        <p>
          You can unsubscribe from our newsletters or promotional communications
          at any time. Furthermore, you have the right to access, modify, or
          delete your personal information stored with us.
        </p>

        <h3>Updates To This Policy:</h3>

        <p>
          LeadBud.io may update this policy periodically. We'll notify users of
          any significant changes and always display the most recent policy on
          this page.
        </p>

        <h3>Contact Us:</h3>

        <p>
          For any questions or concerns regarding this Privacy Policy, please
          contact us at <a href="mailto:info@leadbud.io">info@leadbud.io</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
