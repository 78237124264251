import React from "react";
import OwlCarousel from "react-owl-carousel";
import TestimonialSliderItem from "../../../../components/testimonialSliderItem/testimonialSliderItem";

import userDP from "../../../../assets/img/user-1.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const HomeTestimonial = () => {
  const data = [
    {
      dp: userDP,
      userName: "Travis Bradley",
      companyName: "ABC Company",
      review:
        "LeadBud has been a game-changer for our business. It's incredibly user-friendly, and the automation features have saved us a ton of time. Our lead generation has never been more efficient!",
    },
    {
      dp: userDP,
      userName: "Travis Bradley",
      companyName: "ABC Company",
      review:
        "LeadBud has been a game-changer for our business. It's incredibly user-friendly, and the automation features have saved us a ton of time. Our lead generation has never been more efficient!",
    },
    {
      dp: userDP,
      userName: "Travis Bradley",
      companyName: "ABC Company",
      review:
        "LeadBud has been a game-changer for our business. It's incredibly user-friendly, and the automation features have saved us a ton of time. Our lead generation has never been more efficient!",
    },
  ];

  const options = {
    dots: false,
    responsiveClass: true,
    loop: true,
    autoplay: true,
    smartSpeed: 300,
    autoplayTimeout: 2000,
    nav: true,
    navText: [
      '<i class="fa-solid fa-arrow-left"></i>',
      '<i class="fa-solid fa-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 1.5,
      },
      1050: {
        items: 1.5,
      },
      1200: {
        items: 2.5,
      },
      1400: {
        items: 2.5,
      },
    },
  };

  return (
    <div className="section home-testimonials">
      <div className="container-fluid">
        <div className="content-area padding-left">
          <h2 className="aos-item aos-inint aos-animate">
            What Other Customers
            <br />
            Says About Lead
          </h2>
        </div>

        <OwlCarousel className="owl-carousel padding-left" {...options}>
          {data.map((item, i) => (
            <TestimonialSliderItem
              key={i}
              userDP={item.dp}
              userName={item.userName}
              companyName={item.companyName}
              review={item.review}
            />
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default HomeTestimonial;
