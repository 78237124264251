import React from "react";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
import { Link } from "react-router-dom";
const ContactAndContact = () => {
  return (
    <div className="section connect-and-contact">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div className="content">
              <h2>Connect, Collaborate, Conquer Together</h2>

              <p>
                Reach out to LeadBud.io. Whether you have queries, feedback, or
                ideas, we're here to listen and assist. Let's shape growth
                together.
              </p>

              <ul className="connect">
                <li className="phone">
                  <a style={{ color: "#000" }} href="tel:+1 (123) 456-7890">
                    +1 (123) 456-7890
                  </a>
                </li>
                <li className="mail">
                  <a style={{ color: "#000" }} href="mailto:support@leadbud.io">
                    support@leadbud.io
                  </a>
                </li>
                <li className="location">
                  123 Growth Avenue, Tech Park,
                  <br />
                  Silicon Valley, CA 94101, USA
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div className="form-area">
              <div className="upper">
                <h3>Get In Touch</h3>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    placeholder="First Name*"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    placeholder="Last Name*"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="email"
                    placeholder="Email Address*"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                  >
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <textarea
                    className="form-control"
                    placeholder="Query*"
                  ></textarea>
                </div>

                <div className="button-area">
                  <Link to={"/"} className="btn btn-submit">
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactAndContact;
