import React, { useState } from "react";
import { Link } from "react-router-dom";
import googleImage from "../../assets/img/auth-pages/google.png";
import flagImage from "../../assets/img/auth-pages/flag.png";
import passwordIcon from "../../assets/img/auth-pages/password-icon.png";
import bgSignUpImage from "../../assets/img/auth-pages/sign-up-image.png";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";

const SignupForm = () => {
  const [updateMe, setUpdateMe] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleOnClick = () => {
    if (showPassword) {
      return setShowPassword(false);
    }
    setShowPassword(true);
  };

  const onChange = () => {
    if (updateMe) {
      return setUpdateMe(false);
    }
    setUpdateMe(true);
  };

  return (
    <div className="main">
      <div className="container">
        <div className="login-signup">
          <div className="login-signup-area">
            <div className="content-area text-center">
              <h1>Sign Up</h1>
              <p>
                Sign up and grow your business
                <br />
                with Leadbud.io
              </p>

              <div className="button-area">
                <Link to={"/users/login"} className="btn btn-second">
                  <img src={googleImage} className="img-fluid" alt="google" />
                  Sign Up With Google
                </Link>
              </div>
            </div>

            <p className="text-center my-4">OR</p>

            <div className="form-area">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    placeholder="First name*"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    placeholder="Last name*"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="email"
                    placeholder="Email Address*"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 select">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>+1 (000 0000000)</option>
                    <option value="1">+1 (000 0000000)</option>
                    <option value="2">+1 (000 0000000)</option>
                    <option value="3">+1 (000 0000000)</option>
                  </select>
                  <img src={flagImage} className="img-fluid" alt="flag-icon" />
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control password"
                    placeholder="Password*"
                  />

                  {showPassword ? (
                    <i
                      style={{
                        float: "right",
                        position: "absolute",
                        right: "30px",
                        width: "20px",
                        bottom: "36px",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                      className="img-fluid"
                      onClick={handleOnClick}
                      class="fa-regular fa-eye"
                    ></i>
                  ) : (
                    <img
                      onClick={handleOnClick}
                      src={passwordIcon}
                      className="img-fluid"
                      alt="password-icon"
                      style={{ userSelect: "none", cursor: "pointer" }}
                    />
                  )}
                </div>

                <div className="send-leads">
                  <div className="round">
                    <input
                      type="checkbox"
                      onChange={onChange}
                      checked={updateMe}
                      id="checkbox"
                    />
                    <label htmlFor="checkbox"></label>
                  </div>
                  <span>Send me Lead updates via email</span>
                </div>

                <div className="button-area">
                  <Link to={"/"} className="btn btn-sign-up">
                    Sign Up With Email
                  </Link>
                </div>
              </div>

              <div className="lower text-center">
                <p>
                  Already have an account?
                  <span>
                    <Link to={"/users/login"}>Login</Link>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="bg-with-image">
            <div className="image-area">
              <img
                src={bgSignUpImage}
                className="img-fluid"
                alt="sign-up-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
