import React from "react";
import Logo from "../../assets/Utilities/whitelogo.png";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import Visa from "../../assets/Utilities/visa.png";
import Paypal from "../../assets/Utilities/paypal.png";
import Master from "../../assets/Utilities/master.png";
import Payooner from "../../assets/Utilities/payooneer.png";
import Pay from "../../assets/Utilities/pay.png";
import Wirepay from "../../assets/Utilities/wirepay.png";
import { Link } from "react-router-dom";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";

export default function Footer() {
  return (
    <div
      className=" text-white pt-5"
      style={{ backgroundColor: "#030621", marginTop: "90px" }}
    >
      <div className="container">
        <div className="row text-center text-sm-start align-items-center">
          <div className="col-12 col-sm-4 col-md-2 mb-3 mb-sm-0 ">
            <img className="img-fluid" src={Logo} alt="Logo" />
          </div>
          <div className="col-12 col-sm-8 col-md-6 mb-3 mb-sm-0 ">
            <ul
              className="list-inline m-0  p-0 "
              style={{ fontSize: "14px", color: "#7b7b7b" }}
            >
              <li className="list-inline-item mx-2">
                <Link className="text-para" to="/">
                  Home
                </Link>
              </li>
              <li className="list-inline-item mx-2">
                <Link className="text-para" to="/">
                  Feature
                </Link>
              </li>
              <li className="list-inline-item mx-2">
                <Link className="text-para" to="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="list-inline-item mx-2">
                <Link className="text-para" to="/">
                  Tutorials
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4 mb-3 mb-sm-0 ">
            <div className="d-flex justify-content-center justify-content-md-end">
              <Link className="text-green mx-1" to="/">
                <FaFacebookF
                  className="border border-green p-2 rounded-circle"
                  size={30}
                />
              </Link>
              <Link className="text-green mx-1" to="/">
                <FaLinkedinIn
                  className="border border-green p-2 rounded-circle"
                  size={30}
                />
              </Link>
              <Link className="text-green mx-1" to="/">
                <FaTwitter
                  className="border border-green p-2 rounded-circle"
                  size={30}
                />
              </Link>
              <Link className="text-green mx-1" to="/">
                <FaInstagram
                  className="border border-green p-2 rounded-circle"
                  size={30}
                />
              </Link>
            </div>
          </div>
        </div>

        <hr style={{ marginTop: "40px", marginBottom: "40px" }} />

        <div
          className="row text-center text-sm-start"
          style={{
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <div className="col-6 col-md-2  mb-md-0 footer-links ">
            <h5 className="text-lg font-semibold text-white">Tools</h5>
            <ul className="list-unstyled mt-2">
              <li>
                <Link className="text-para" to="/">
                  Leadbud
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-2 mb-md-0 footer-links ">
            <h5 className="text-lg font-semibold text-white">Leadbud</h5>
            <ul className="list-unstyled mt-2">
              <li>
                <Link className="text-para" to="/">
                  About
                </Link>
              </li>
              <li>
                <Link className="text-para" to="/">
                  Tutorials
                </Link>
              </li>
              <li>
                <Link className="text-para" to="/affiliate-program">
                  Affiliate Program
                </Link>
              </li>
              <li>
                <Link className="text-para" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="text-para" to="/terms-condition">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-2  mb-md-0 footer-links ">
            <h5 className="text-lg font-semibold text-white">
              Custom Solutions
            </h5>
            <ul className="list-unstyled mt-2">
              <li>
                <Link className="text-para" to="/">
                  Exact Web Data
                </Link>
              </li>
              <li>
                <Link className="text-para" to="/">
                  Data Monitoring
                </Link>
              </li>
              <li>
                <Link className="text-para" to="/">
                  Custom Database
                </Link>
              </li>
              <li>
                <Link className="text-para" to="/">
                  Custom Bots
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-2  mb-md-0 footer-links ">
            <h5 className="text-lg font-semibold text-white">Development</h5>
            <ul className="list-unstyled mt-2">
              <li>
                <Link className="text-para" to="/">
                  Changelog
                </Link>
              </li>
              <li>
                <Link className="text-para" to="/">
                  Bot API (Public Beta)
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-2  mb-md-0 footer-links ">
            <h5 className="text-lg font-semibold text-white">
              Help and Support
            </h5>
            <ul className="list-unstyled mt-2">
              <li>
                <Link className="text-para" to="/">
                  Contact Info
                </Link>
              </li>
              <li>
                <Link className="text-para" to="/">
                  Info@leadbud.io
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div
          className="  text-center text-sm-start  copyright-icons"
          style={{
            paddingTop: "40px",
            paddingBottom: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="mb-3 mb-md-0  order-md-2" style={{ flexShrink: 0 }}>
            <div
              className="d-flex  footer-images "
              style={{ flexWrap: "wrap" }}
            >
              <img
                className="mx-1"
                style={{ marginTop: "5px", flexShrink: 0 }}
                src={Visa}
                alt="Visa"
              />
              <img
                className="mx-1"
                style={{ marginTop: "5px", flexShrink: 0 }}
                src={Paypal}
                alt="Paypal"
              />
              <img
                className="mx-1"
                style={{ marginTop: "5px", flexShrink: 0 }}
                src={Master}
                alt="Master"
              />
              <img
                className="mx-1"
                style={{ marginTop: "5px", flexShrink: 0 }}
                src={Payooner}
                alt="Payooner"
              />
              <img
                className="mx-1"
                style={{ marginTop: "5px", flexShrink: 0 }}
                src={Pay}
                alt="Pay"
              />
              <img
                className="mx-1"
                style={{ marginTop: "5px", flexShrink: 0 }}
                src={Wirepay}
                alt="Wirepay"
              />
            </div>
          </div>
          <div className="order-md-1" style={{ flexShrink: 0 }}>
            <p
              className="text-sm md:text-md text-para mb-0 "
              style={{ fontSize: "14px", color: "#7b7b7b" }}
            >
              Copyright © 2023 Leadbud.io All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
