import React from "react";
import TermsAndConditionTitle from "./components/termsAndConditionTitle/termsAndConditionTitle";
import TermsAndConditionContent from "./components/termsAndConditionContent/termsAndConditionContent";

const TermsAndCondition = () => {
  return (
    <div className="main-body">
      <TermsAndConditionTitle />
      <TermsAndConditionContent />
    </div>
  );
};

export default TermsAndCondition;
