import React from "react";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import { Link } from "react-router-dom";
const PricingPackage = ({
  key,
  packageName,
  packageDesc,
  price,
  packageInclude,
  whatsIcluded,
}) => {
  return (
    <div
      key={key}
      className=" col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12 row-item"
    >
      <div className="item">
        <div className="content-top">
          <p className="tag">{packageName}</p>

          <p>{packageDesc}</p>
          <h3>
            <span className="sign">$</span>
            {price}
            <span>/month</span>
          </h3>
        </div>
        <ul>
          {packageInclude.map((item, i) => (
            <li key={i} className={`${item.check ? "check" : "un"}`}>
              {item.value}
            </li>
          ))}
        </ul>

        <div className="button-area">
          <div
            className="magnetic"
            style={{ transform: " matrix(1, 0, 0, 1, 0, 0)" }}
          >
            <Link to={"/pricing"} className="btn magnet-button">
              Get Started
            </Link>
          </div>
        </div>

        <p>What's included</p>

        <ul>
          {whatsIcluded.map((item, i) => (
            <li key={i} className={`${item.check ? "check" : "un"}`}>
              {item.value}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PricingPackage;
