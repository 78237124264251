import React from "react";
import referalIcon from "../../../../assets/img/affiliate/referal-icon.png";
import noCashIcon from "../../../../assets/img/affiliate/no-cash.png";
import dashboardIcon from "../../../../assets/img/affiliate/dashboard.png";
import limitLessIcon from "../../../../assets/img/affiliate/limit-less.png";
import hr24 from "../../../../assets/img/affiliate/24hr.png";
import mediaPackageIcon from "../../../../assets/img/affiliate/media-package.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
import { Link } from "react-router-dom";
const WhyPopular = () => {
  return (
    <div className="section why-popular">
      <div className="container">
        <div className="content-area text-center">
          <h2>
            Why Is Leadbud.io
            <br />
            Affiliate Program So Popular?
          </h2>

          <div className="popular-area">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-item">
                <div className="item">
                  <img src={referalIcon} className="img-fluid" alt="referal" />
                  <p>Massive 25% Referral Commission</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-item">
                <div className="item">
                  <img src={noCashIcon} className="img-fluid" alt="no-cash" />
                  <p>No Cash-Out Limit</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-item">
                <div className="item">
                  <img
                    src={dashboardIcon}
                    className="img-fluid"
                    alt="dashboard"
                  />
                  <p>Convenient Affiliate Dashboard</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-item">
                <div className="item">
                  <img
                    src={limitLessIcon}
                    className="img-fluid"
                    alt="limitless"
                  />
                  <p>Limitless Number Of Referrals</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-item">
                <div className="item">
                  <img src={hr24} className="img-fluid" alt="24hr" />
                  <p>24/7 Human Support</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-item">
                <div className="item">
                  <img
                    src={mediaPackageIcon}
                    className="img-fluid"
                    alt="media"
                  />
                  <p>Helpful Ad & Media Package</p>
                </div>
              </div>
            </div>

            <div className="button-area text-center">
              <Link to={"/"} className="btn btn-apply">
                Apply Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyPopular;
