import React from "react";
import { Link } from "react-router-dom";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const ReadyToSell = ({ heading, heading1 }) => {
  return (
    <div className="section ready-to-sell">
      <div className="container">
        <div className="item">
          <div className="content-area text-center">
            {heading1 ? (
              <h2>
                {heading} <br /> {heading1}
              </h2>
            ) : (
              <h2>{heading}</h2>
            )}
            <p>
              Join Our Affiliate Program: Partner with LeadBud and earn
              attractive commissions
              <br />
              by promoting our powerful lead generation tool. Start earning
              today!
            </p>

            <div className="button-area">
              <Link to={"/"} className="btn btn-start">
                Start to Free
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadyToSell;
