import React from "react";
import bannerImage from "../../../../assets/img/contact-us/banner-image.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const ContactUsTitle = () => {
  return (
    <div className="title-area contact-title">
      <div className="container">
        <div className="content-area">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="content">
                <h1>Contact Us</h1>

                <p>
                  Find out how we can help you scale your business by getting in
                  touch.
                </p>
              </div>
            </div>

            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <div className="image-area contact-img">
                <img
                  src={bannerImage}
                  className="img-fluid"
                  alt="banner-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsTitle;
