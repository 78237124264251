import React from "react";
import steps from "../../../../assets/img/steps.png";
import step1 from "../../../../assets/img/step-1.png";
import step2 from "../../../../assets/img/step-2.png";
import step3 from "../../../../assets/img/step-3.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const HowLeadbudsWoks = () => {
  return (
    <div className="section how-leadbud-works">
      <div className="container">
        <div className="content-area text-center">
          <h2 className="aos-item aos-inint aos-animate">How Leadbud Works?</h2>
          <p className="aos-item aos-inint aos-animate">
            At LeadBud, our lead generation process is simple and effective. We
            empower you
            <br />
            to capture, nurture, and convert leads effortlessly.
          </p>
        </div>

        <div className="steps text-center d-flex">
          <img src={steps} className="img-fluid" alt="steps" />
        </div>

        <div className="mobile-steps">
          <div className="step">
            <div className="content">
              <h4>Connect Store</h4>
              <p>
                Send Allsome Your Inventory And Online Your Preferred Outlets
              </p>
            </div>

            <img src={step1} className="img-fluid" alt="step-1" />
          </div>

          <div className="step">
            <div className="content">
              <h4>Autorun Operations</h4>
              <p>Auto Sync Paid Orders And Wake Teams To Ship The Orders</p>
            </div>

            <img src={step2} className="img-fluid" alt="step-2" />
          </div>

          <div className="step">
            <div className="content">
              <h4>Happy Buyers</h4>
              <p>Self-Service Post Purchase Needs Anytime, Everywhere</p>
            </div>

            <img src={step3} className="img-fluid" alt="step-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowLeadbudsWoks;
