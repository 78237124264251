import { FaGraduationCap } from "react-icons/fa";

export const leadbud = [
  {
    link: "/facebookpage",
    img: <FaGraduationCap className="text-[30px]" />,
    heading: "Prospecting",
    para: "Integrate Leadbuds Data Effortlessly into Your Marketing",
  },
  {
    link: "/salesai",
    img: <FaGraduationCap className="text-[30px]" />,
    heading: "Artificial Intelligence",
    para: "Integrate Leadbuds Data Effortlessly into Your Marketing",
  },
  {
    link: "/emailfinder",
    img: <FaGraduationCap className="text-[30px]" />,
    heading: "Email Finder",
    para: "Integrate Leadbuds Data Effortlessly into Your Marketing",
  },
  {
    link: "/emailverify",
    img: <FaGraduationCap className="text-[30px]" />,
    heading: "Email Verifier",
    para: "Integrate Leadbuds Data Effortlessly into Your Marketing",
  },
  {
    link: "/b2bdatabase",
    img: <FaGraduationCap className="text-[30px]" />,
    heading: "B2B Companies",
    para: "Integrate Leadbuds Data Effortlessly into Your Marketing",
  },
];

export const solution = [
  {
    link: "/b2bdatabase",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Snovio Academy",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/b2bdatabase",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Snovio Academy",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/b2bdatabase",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Snovio Academy",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/b2bdatabase",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Snovio Academy",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/b2bdatabase",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Snovio Academy",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/b2bdatabase",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Snovio Academy",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/b2bdatabase",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Snovio Academy",
    para: "Expert Lead-crush courses on growing states.",
  },
];

export const pages = [
  {
    link: "/blog",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Blog",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/pricing",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Pricing",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/affiliate-program",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Affiliate Program",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/contact-us",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Contact Us",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/privacy-policy",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Privacy policy",
    para: "Expert Lead-crush courses on growing states.",
  },
  {
    link: "/terms-condition",
    img: <FaGraduationCap className="text-[30px]" />,
    mediaheading: "Terms And Condition",
    para: "Expert Lead-crush courses on growing states.",
  },
];
