import React from "react";
import partnerImage from "../../../../assets/img/affiliate/partner-iamge.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const WhoCanPartner = () => {
  return (
    <div className="section who-can-partner">
      <div className="container">
        <div className="partner-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="content">
                <h2>
                  Who Can Become
                  <br />
                  An Affiliate Partner?
                </h2>

                <p>
                  Join Our Affiliate Program: Discover The Steps To Become A
                  Valued Leadbud.Io Affiliate Partner And Start Earning
                  Commissions While Promoting A Game-Changing Lead Generation
                  Solution.
                </p>

                <p>
                  Anyone Who Wants To Help Other Sales And Marketing
                  Professionals Grow Their Business
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="image-area float-lg-end float-md-none">
                <img src={partnerImage} className="img-fluid" alt="partner" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoCanPartner;
