import React from "react";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const BlogTitle = () => {
  return (
    <div className="pricing-title-area">
      <div className="container">
        <div className="content-area text-center blog">
          <h2>Revolutionizing Your Growth Strategy One Tool at a Time</h2>
          <h1>
            <span>Empower. Grow. Lead.</span> With LeadBud.io.
          </h1>

          <p>
            LeadBud.io: Your gateway to unmatched digital growth. Harness
            powerful tools
            <br />
            and transform your business trajectory.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogTitle;
