import React from "react";
import PricingTitle from "./components/pricingTitle.jsx/pricingTitle";
import OurPricing from "./components/ourPricing/ourPricing";
import ApiIntegration from "../home/components/apiIntegration/apiIntegration";
import OurCharacteristic from "./components/ourCharacteristic/ourCharacteristic";
import Excellence from "./components/excellence/excellence";
import HomeFaqs from "../home/components/homeFaqs/homeFaqs";
import ReadyToSell from "./components/readyToSell/readyToSell";

const Pricing = () => {
  return (
    <div className="main-body">
      <PricingTitle />
      <OurPricing />
      <OurCharacteristic />
      <ApiIntegration />
      <Excellence />
      <ReadyToSell heading={"Ready To Sell More?"} />
      <HomeFaqs />
    </div>
  );
};

export default Pricing;
