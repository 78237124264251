import React from "react";
import { Link } from "react-router-dom";
import hubsPot from "../../../../assets/img/brands/hubspot.png";
import zapier from "../../../../assets/img/brands/zapier.png";
import canva from "../../../../assets/img/brands/canva.png";
import dropbox from "../../../../assets/img/brands/dropbox.png";
import comcast from "../../../../assets/img/brands/comcast.png";
import moosend from "../../../../assets/img/brands/moosend.png";
import sendpulse from "../../../../assets/img/brands/sendpulse.png";
import docusign from "../../../../assets/img/brands/docusign.png";
import zendesk from "../../../../assets/img/brands/zendesk.png";
import sanpChat from "../../../../assets/img/brands/sanp-chat.png";
import linkedin from "../../../../assets/img/brands/linkedin.png";
import instagram from "../../../../assets/img/brands/instagram.png";
import payooneer from "../../../../assets/img/brands/payooneer.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";

const ApiIntegration = () => {
  return (
    <div className="section api-integrations">
      <div className="container">
        <div className="content-area">
          <div className="content">
            <div className="left">
              <h2 className="aos-item aos-inint aos-animate">
                API & Inegrations
              </h2>
              <p className="aos-item aos-inint aos-animate">
                Your favorite apps integrate with us No coding experience
                necessary.
                <br />
                Simply connect and synchronize your workflow across platforms.
              </p>
            </div>

            <div className="right">
              <p className="aos-item aos-inint aos-animate">
                Start using for free or have a look at our prices.
                <br /> Start as low as $10.
              </p>
            </div>
          </div>

          {/* infinite-scroll-area  */}

          <article className="wrapper">
            <div className="marquee">
              <div className="marquee_group">
                <img src={hubsPot} className="img-fluid" alt="hub-spot" />
                <img src={zapier} className="img-fluid" alt="zapier" />
                <img src={hubsPot} className="img-fluid" alt="hub-spot" />
                <img src={canva} className="img-fluid" alt="canva" />
                <img src={dropbox} className="img-fluid" alt="dropbox" />
              </div>
              <div aria-hidden={true} className="marquee_group">
                <img src={hubsPot} className="img-fluid" alt="hub-spot" />
                <img src={zapier} className="img-fluid" alt="zapier" />
                <img src={hubsPot} className="img-fluid" alt="hub-spot" />
                <img src={canva} className="img-fluid" alt="canva" />
                <img src={dropbox} className="img-fluid" alt="dropbox" />
              </div>
            </div>

            <div className="marquee marquee-reverse">
              <div className="marquee_group">
                <img src={comcast} className="img-fluid" alt="comcast" />
                <img src={moosend} className="img-fluid" alt="moosend" />
                <img src={sendpulse} className="img-fluid" alt="sendpulse" />
                <img src={docusign} className="img-fluid" alt="docu" />
                <img src={zendesk} className="img-fluid" alt="zendesk" />
              </div>
              <div aria-hidden={true} className="marquee_group">
                <img src={comcast} className="img-fluid" alt="comcast" />
                <img src={moosend} className="img-fluid" alt="moosend" />
                <img src={sendpulse} className="img-fluid" alt="sendpulse" />
                <img src={docusign} className="img-fluid" alt="docu" />
                <img src={zendesk} className="img-fluid" alt="zendesk" />
              </div>
            </div>

            <div className="marquee">
              <div className="marquee_group">
                <img src={docusign} className="img-fluid" alt="docusign" />
                <img src={sanpChat} className="img-fluid" alt="sanp" />
                <img src={linkedin} className="img-fluid" alt="linkedin" />
                <img src={instagram} className="img-fluid" alt="instagram" />
                <img src={payooneer} className="img-fluid" alt="dropbox" />
              </div>
              <div aria-hidden={true} className="marquee_group">
                <img src={docusign} className="img-fluid" alt="docusign" />
                <img src={sanpChat} className="img-fluid" alt="sanp" />
                <img src={linkedin} className="img-fluid" alt="linkedin" />
                <img src={instagram} className="img-fluid" alt="instagram" />
                <img src={payooneer} className="img-fluid" alt="dropbox" />
              </div>
            </div>
          </article>

          <div className="button-area text-center">
            <Link to={"/"} className="btn btn-start">
              Get Started For free
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiIntegration;
