import React from "react";
import HomeBanner from "./components/homeBanner/homeBanner";
import HomeFeatures from "./components/homeFeatures/homeFeatures";
import MoreSaasTools from "./components/moreSaasTools/moreSaasTools";
import HowLeadbudsWoks from "./components/howLeadbudWorks/howLeadbudWorks";
import HomePlatformCapabilities from "./components/homePlatformCapabilities/homePlatformCapabilities";
import ApiIntegration from "./components/apiIntegration/apiIntegration";
import DataRevenue from "./components/dataRevenue/dataRevenue";
import HomeFaqs from "./components/homeFaqs/homeFaqs";
import HomeTestimonial from "./components/homeTestimonial/homeTestimonial";

const Home = () => {
  return (
    <div className="main-body">
      <HomeBanner />

      <HomeFeatures />

      <MoreSaasTools />

      <HowLeadbudsWoks />

      <HomePlatformCapabilities />

      <ApiIntegration />

      <DataRevenue />

      <HomeFaqs />

      <HomeTestimonial />
    </div>
  );
};

export default Home;
