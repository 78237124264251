import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import bannerImage from "../../../../assets/img/banner-image.png";
import bannerMobile from "../../../../assets/img/banner-mobile.png";
import facebookIcon from "../../../../assets/img/icons/facebook.svg";
import instagramIcon from "../../../../assets/img/icons/instagram.svg";
import twitterIcon from "../../../../assets/img/icons/twitter.svg";
import linkedInIcon from "../../../../assets/img/icons/linkedin.svg";
import slackIcon from "../../../../assets/img/icons/slack.svg";
import whatsAppIcon from "../../../../assets/img/icons/whatsapp.svg";
import pinterestIcon from "../../../../assets/img/icons/pinterest.svg";
import reditIcon from "../../../../assets/img/icons/redit.svg";
import canvaImg from "../../../../assets/img/canva.png";
import googleImg from "../../../../assets/img/google.png";
import dropboxImg from "../../../../assets/img/dropbox.png";
import comcastImg from "../../../../assets/img/comcast.png";
import mooseendImg from "../../../../assets/img/mooseend.png";
import sendpulseImg from "../../../../assets/img/sendpulse.png";
import docuSignImg from "../../../../assets/img/docu-sign.png";
import "aos/dist/aos.css";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const HomeBanner = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    Aos.init({
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      },
    });
  });

  return (
    <React.Fragment>
      <div className="home-banner">
        <div className="container">
          <div className="content-area">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="content">
                  <h1>
                    <span>
                      Elevate Your
                      <br />
                      Leads, Multiply Your Sales,
                    </span>
                    <br />
                    Maximize Your Revenue
                  </h1>

                  <p>
                    Your Gateway to Seamless B2B and B2C Lead Generation from
                    Any Source, Anytime, Anywhere
                  </p>

                  <div className="button-area aos-init aos-animate">
                    <Link to={"/users/login"} className="btn btn-get-started">
                      Get Started for free
                    </Link>
                  </div>
                </div>

                <div className="items">
                  <div className="item">
                    <i className="fa-regular fa-credit-card"></i>
                    <p>No Card Required</p>
                  </div>
                  <div className="item">
                    <i className="fa-regular fa-clock"></i>
                    <p>Quick Setup</p>
                  </div>
                  <div className="item">
                    <i className="fa-solid fa-headset"></i>
                    <p>Award-winning customer care</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="image-area">
                  <div className="anim">
                    <img
                      src={bannerImage}
                      className="img-fluid main"
                      alt="banner-image"
                    />

                    <img
                      src={bannerMobile}
                      className="img-fluid mobile"
                      alt="banner-image"
                    />

                    <img
                      src={facebookIcon}
                      className="img-fluid icon fb"
                      alt="fb"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    />
                    <img
                      src={instagramIcon}
                      className="img-fluid icon insta"
                      alt="instagram"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                      data-aos-delay="200"
                    />
                    <img
                      src={twitterIcon}
                      className="img-fluid icon twitter"
                      alt="twitter"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      data-aos-delay="300"
                    />
                    <img
                      src={linkedInIcon}
                      className="img-fluid icon linkedin"
                      alt="linkedin"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="400"
                    />
                    <img
                      src={slackIcon}
                      className="img-fluid icon slack"
                      alt="slack"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                      data-aos-delay="500"
                    />
                    <img
                      src={whatsAppIcon}
                      className="img-fluid icon whatsapp"
                      alt="whatsapp"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                      data-aos-delay="600"
                    />
                    <img
                      src={pinterestIcon}
                      className="img-fluid icon pin"
                      alt="pinterest"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-delay="600"
                    />
                    <img
                      src={reditIcon}
                      className="img-fluid icon redit"
                      alt="reddit"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="600"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  home-brands  */}

      <div class="home-brands">
        <div class="container">
          <div class="items-area">
            <div class="item aos-animate aos-init">
              <img src={canvaImg} class="img-fluid" alt="canva" />
            </div>

            <div class="item aos-animate aos-init">
              <img src={googleImg} class="img-fluid" alt="google" />
            </div>

            <div class="item aos-animate aos-init">
              <img src={dropboxImg} class="img-fluid" alt="dropbox" />
            </div>

            <div class="item aos-animate aos-init">
              <img src={comcastImg} class="img-fluid" alt="comcast" />
            </div>

            <div class="item aos-animate aos-init">
              <img src={mooseendImg} class="img-fluid" alt="moosend" />
            </div>

            <div class="item aos-animate aos-init">
              <img src={sendpulseImg} class="img-fluid" alt="sendpulse" />
            </div>

            <div class="item aos-animate aos-init">
              <img src={docuSignImg} class="img-fluid" alt="docu-sign" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeBanner;
