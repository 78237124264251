import React from "react";
import Header from "../header/header";
import { Outlet } from "react-router-dom";
import Footer from "../footer/footer";
import ScrollToTop from "../scrollToTop/scrollToTop";

const HomeLayout = () => {
  return (
    <ScrollToTop>
      <Header />
      <div>
        <Outlet />
      </div>
      <Footer />
    </ScrollToTop>
  );
};

export default HomeLayout;
