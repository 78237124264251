import { createBrowserRouter } from "react-router-dom";
import LoginForm from "../screens/loginForm/loginForm";
import SignupForm from "../screens/signupForm/signupForm";
import HomeLayout from "../components/homeLayout/homeLayout";
import Home from "../screens/home/home";
import Pricing from "../screens/pricing/pricing";
import AffiliateProgram from "../screens/affiliateProgram/affiliateProgram";
import Blog from "../screens/blog/blog";
import ContactUs from "../screens/contact-us/contactUs";
import PrivacyPloicy from "../screens/privacyPolicy/privacyPolicy";
import TermsAndCondition from "../screens/termsAndCondition/termsAndCondition";

const router = createBrowserRouter([
  {
    path: "/users/login",
    element: <LoginForm />,
  },
  {
    path: "/users/sign-up",
    element: <SignupForm />,
  },
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "affiliate-program",
        element: <AffiliateProgram />,
      },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPloicy />,
      },
      {
        path: "terms-condition",
        element: <TermsAndCondition />,
      },
    ],
  },
]);

export default router;
