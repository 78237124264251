import React from "react";
import PlatformDesignImage from "../../../../assets/img/platform-design-image.png";
import realTimeData from "../../../../assets/img/real-time-data.png";
import userFriendlyDashboard from "../../../../assets/img/user-friendly-dashboard.png";
import everyPlatform from "../../../../assets/img/every-platform.png";
import scrappingIcon from "../../../../assets/img/scraping-icon.png";
import multipleImports from "../../../../assets/img/multiple-imports.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const HomeFeatures = () => {
  return (
    <div className="section home-features">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div className="image-area aos-animate aos-init">
              <img
                src={PlatformDesignImage}
                className="img-fluid"
                alt="platform-design"
              />
            </div>
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
            <div className="content">
              <h3 className="aos-init aos-animate">
                We've designed our platform to offer a rich set of features that
                cater to your lead generation needs.
              </h3>

              <p className="aos-item aos-inint aos-animate">
                Here's a closer look at what makes LeadBud a game-changer:
              </p>

              <div className="features-area">
                <ul>
                  <li className="aos-item aos-inint aos-animate">
                    <img
                      src={realTimeData}
                      className="img-fluid"
                      alt="real-time-data"
                    />
                    Real-Time Data Update
                  </li>
                  <li className="aos-item aos-inint aos-animate">
                    <img
                      src={userFriendlyDashboard}
                      className="img-fluid"
                      alt="user-friendly-dashboard"
                    />
                    User-Friendly Dashboard
                  </li>
                  <li className="aos-item aos-inint aos-animate">
                    <img
                      src={everyPlatform}
                      className="img-fluid"
                      alt="every-platform"
                    />
                    Every Platform Crawling
                  </li>
                  <li className="aos-item aos-inint aos-animate">
                    <img
                      src={scrappingIcon}
                      className="img-fluid"
                      alt="real-time-data"
                    />
                    Customizable Scraping
                  </li>
                  <li className="aos-item aos-inint aos-animate">
                    <img
                      src={multipleImports}
                      className="img-fluid"
                      alt="multiple-imports"
                    />
                    Multiple Import Options
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFeatures;
