import React, { useState } from "react";
import { Link } from "react-router-dom";
import googleImage from "../../assets/img/auth-pages/google.png";
import passwordIcon from "../../assets/img/auth-pages/password-icon.png";
import bgLoginImage from "../../assets/img/auth-pages/login.png";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";

const LoginForm = () => {
  const [rememberMe, setRememberMe] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleOnClick = () => {
    if (showPassword) {
      return setShowPassword(false);
    }
    setShowPassword(true);
  };

  const onChange = () => {
    if (rememberMe) {
      return setRememberMe(false);
    }
    setRememberMe(true);
  };

  return (
    <div className="main">
      <div className="container">
        <div className="login-signup">
          <div className="login-signup-area login">
            <div className="content-area text-center">
              <h1>Login</h1>
              <p>
                Sign up and grow your business
                <br />
                with Leadbud.io
              </p>

              <div className="button-area">
                <Link to={"/users/login"} className="btn btn-second">
                  <img src={googleImage} className="img-fluid" alt="google" />
                  Login With Google
                </Link>
              </div>
            </div>

            <p className="text-center my-4">OR</p>

            <div className="form-area">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="email"
                    placeholder="Email Address*"
                    className="form-control"
                  />
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control password"
                    placeholder="Password*"
                  />

                  {showPassword ? (
                    <i
                      style={{
                        float: "right",
                        position: "absolute",
                        right: "30px",
                        width: "20px",
                        bottom: "36px",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                      className="img-fluid"
                      onClick={handleOnClick}
                      class="fa-regular fa-eye"
                    ></i>
                  ) : (
                    <img
                      onClick={handleOnClick}
                      src={passwordIcon}
                      className="img-fluid"
                      alt="password-icon"
                      style={{ userSelect: "none", cursor: "pointer" }}
                    />
                  )}
                </div>

                <div className="send-leads">
                  <div className="round">
                    <input
                      type="checkbox"
                      onChange={onChange}
                      checked={rememberMe}
                      id="checkbox"
                    />
                    <label htmlFor="checkbox"></label>
                  </div>
                  <span>Remember Me</span>
                </div>

                <div className="button-area">
                  <Link to={"/"} className="btn btn-sign-up">
                    Login Up With Email
                  </Link>
                </div>
              </div>

              <div className="lower text-center">
                <p>
                  Don't have an account?
                  <span>
                    <Link to={"/users/sign-up"}>Sign Up</Link>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="bg-with-image">
            <div className="image-area">
              <img
                src={bgLoginImage}
                className="img-fluid"
                alt="sign-up-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
