import React from "react";
import personalIcon from "../../../../assets/img/pricing/personal-icon.png";
import awardIcon from "../../../../assets/img/pricing/award-icon.png";
import helpIcon from "../../../../assets/img/pricing/help-icon.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const OurCharacteristic = () => {
  return (
    <div className="section our-characterstics">
      <div className="container">
        <div className="content-area">
          <div className="content text-center">
            <h2>We're With You Every Step Of The Way</h2>
          </div>

          <div className="chars">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 row-item">
                <div className="item text-center">
                  <img
                    src={personalIcon}
                    className="img-fluid"
                    alt="personal-icon"
                  />

                  <div className="desc">
                    <h3>
                      Personal Implementation
                      <br />
                      Manager
                    </h3>

                    <p>
                      Don't know where to start? An implementation manager will
                      help set up your account faster according to your specific
                      goals and obstacles.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 row-item">
                <div className="item text-center">
                  <img src={awardIcon} className="img-fluid" alt="award-icon" />

                  <div className="desc">
                    <h3>
                      Award-Winning
                      <br />
                      Support
                    </h3>

                    <p>
                      Questions, suggestions, troubleshooting ⁠— our customer
                      care team is always here to help you get the answers
                      faster.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 row-item">
                <div className="item text-center">
                  <img src={helpIcon} className="img-fluid" alt="help-icon" />

                  <div className="desc">
                    <h3>
                      Extensive Help
                      <br />
                      Center
                    </h3>

                    <p>
                      Step-by-step tool guides, expert insights,
                      beginner-friendly glossary and a big template library are
                      here to help you get started easier.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCharacteristic;
