import React from "react";
import affiliateBannerImage from "../../../../assets/img/affiliate/banner-image.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
import { Link } from "react-router-dom";
const AffiliateProgramTitle = () => {
  return (
    <div className="title-area">
      <div className="container">
        <div className="content-area">
          <div className="row align-content-center">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="content">
                <h1>
                  Earn Big <span>From Partnership with</span>
                  Leadbud.io
                </h1>

                <p>
                  Earn 25% referral commission by turning people to our
                  award-winning service.
                </p>

                <div className="button-area">
                  <Link to={"/"} className="btn btn-partner">
                    Become a Partner
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <div className="image-area flaot-end">
                <img
                  src={affiliateBannerImage}
                  className="img-fluid"
                  alt="banner-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateProgramTitle;
