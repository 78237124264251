import React, { useEffect, useState } from "react";
import DesktopHeader from "./desktopHeader";
import MobileHeader from "./mobileHeader";

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    console.log(windowWidth);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  return <>{windowWidth >= 992 ? <DesktopHeader /> : <MobileHeader />}</>;
};

export default Header;
