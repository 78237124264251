import { useState } from "react";
import logo from "../../assets/img/logo.svg";
import { leadbud, solution, pages } from "./navBarData";
import { Link } from "react-router-dom";
import Mediaobject from "../mediaObjects/Mediaobject";
import "./Navbar.css";
import { FaChevronDown } from "react-icons/fa";

function DesktopHeader() {
  const [leadbudMenu, setLeadbudMenu] = useState(false);
  const [solutionMenu, setSolutionMenu] = useState(false);
  const [pagesMenu, setPagesMenu] = useState(false);

  const mouseEnterLeadbud = () => {
    setLeadbudMenu(true);
  };

  const mouseLeaveLeadbud = () => {
    setLeadbudMenu(false);
  };

  const mouseEnterSolution = () => {
    setSolutionMenu(true);
  };

  const mouseLeaveSolution = () => {
    setSolutionMenu(false);
  };

  const mouseEnterPages = () => {
    setPagesMenu(true);
  };

  const mouseLeavePages = () => {
    setPagesMenu(false);
  };

  return (
    <header
      className={`bg-bgmain  `}
      style={{ paddingTop: "12px", paddingBottom: "12px" }}
    >
      <nav
        className="container Desktopheader-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          className="brand-links-wrapper"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              className="nav-brand"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link className="nav-brand-wrapper" to={"/"}>
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <ul className={`navbar-ul links-wrapper `}>
              <li
                className="nav-item"
                onMouseEnter={mouseEnterLeadbud}
                onMouseLeave={mouseLeaveLeadbud}
              >
                <Link to={"/"}>Why Leadbud</Link>
                <FaChevronDown style={{ fontSize: "12px" }} />
                {leadbudMenu ? (
                  <div className="custom-dropdown-menu ">
                    {leadbud.map((val, key) => (
                      <Link to={val.link} className="dropDown-item" key={key}>
                        <Mediaobject
                          img={val.img}
                          mediaheading={val.heading}
                          para={val.para}
                        />
                      </Link>
                    ))}
                  </div>
                ) : null}
              </li>
              <li
                className="nav-item"
                onMouseEnter={mouseEnterSolution}
                onMouseLeave={mouseLeaveSolution}
              >
                <Link to={"/"}>Solutions</Link>
                <FaChevronDown style={{ fontSize: "12px" }} />
                {solutionMenu ? (
                  <div className="custom-dropdown-menu ">
                    {solution.map((val, key) => (
                      <Link to={val.link} className="dropDown-item" key={key}>
                        <Mediaobject
                          img={val.img}
                          mediaheading={val.mediaheading}
                          para={val.para}
                        />
                      </Link>
                    ))}
                  </div>
                ) : null}
              </li>
              <li
                className="nav-item"
                onMouseEnter={mouseEnterPages}
                onMouseLeave={mouseLeavePages}
              >
                <Link to={"/"}>Pages</Link>
                <FaChevronDown style={{ fontSize: "12px" }} />
                {pagesMenu ? (
                  <div className="custom-dropdown-menu ">
                    {pages.map((val, key) => (
                      <Link to={val.link} className="dropDown-item" key={key}>
                        <Mediaobject
                          img={val.img}
                          mediaheading={val.mediaheading}
                          para={val.para}
                        />
                      </Link>
                    ))}
                  </div>
                ) : null}
              </li>
              <li className="nav-item">
                <Link to={"/"}>Resources</Link>
              </li>
              <li className="nav-item">
                <Link to={"/"}>Placing</Link>
              </li>
            </ul>
          </div>
          <div style={{ alignSelf: "flex-end" }} className={``}>
            <Link
              to="/"
              className="Desktopheader-btn btn btn-outline-dark me-2"
              style={{ padding: "8px 20px" }}
            >
              Book a Call
            </Link>
            <Link
              to="/users/sign-up"
              style={{ padding: "8px 20px" }}
              className="Desktopheader-btn btn btn-dark"
            >
              SignUp
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default DesktopHeader;
