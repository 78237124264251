import React from "react";
import Para1 from "../para1/Para1";

export default function Mediaobject(props) {
  return (
    <>
      <div
        className="d-flex flex-column text-center text-sm-start flex-sm-row gap-2 "
        style={{ marginRight: "48px", width: "288px" }}
      >
        <div
          className=" text-success mx-auto mx-sm-0"
          style={{ fontSize: "25px", display: "flex" }}
        >
          {props.img}
        </div>
        <div className="media-body">
          <h5
            className="mt-0 fs-4 fw-medium"
            style={{
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "1.75rem",
              color: "#000",
            }}
          >
            {props.mediaheading}
          </h5>
          <Para1 para1={props.para} />
        </div>
      </div>
    </>
  );
}
