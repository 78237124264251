import React from "react";
import "../../assets/styles/main.css";
const TestimonialSliderItem = ({
  key,
  userDP,
  userName,
  companyName,
  review,
}) => {
  return (
    <div className="item" key={key}>
      <i className="fa-solid fa-quote-left quote"></i>
      <div className="user-area">
        <img src={userDP} className="img-fluid" alt="user-1" />
        <div className="info">
          <p className="name">{userName}</p>
          <div className="company">{companyName}</div>
        </div>
      </div>

      <div className="review">
        <p>{review}</p>

        <ul className="star">
          <li>
            <i className="fa-solid fa-star"></i>
          </li>
          <li>
            <i className="fa-solid fa-star"></i>
          </li>
          <li>
            <i className="fa-solid fa-star"></i>
          </li>
          <li>
            <i className="fa-solid fa-star"></i>
          </li>
          <li>
            <i className="fa-solid fa-star"></i>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TestimonialSliderItem;
