import React from "react";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const PrivacyPolicyTitle = () => {
  return (
    <div className="pricing-title-area">
      <div className="container">
        <div className="content-area text-center">
          <h2>Your Privacy, Our Priority</h2>
          <h1>
            <span>LeadBud.io</span> Privacy Policy
          </h1>

          <p>
            At LeadBud.io, we deeply value the trust you place in us. Our
            Privacy Policy
            <br />
            ensures transparent communication
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyTitle;
