import React from "react";
import blogImage1 from "../../../../assets/img/blog/blog-1.png";
import blogImage2 from "../../../../assets/img/blog/blog-2.png";
import blogImage3 from "../../../../assets/img/blog/blog-3.png";
import blogImage4 from "../../../../assets/img/blog/blog-4.png";
import blogImage5 from "../../../../assets/img/blog/blog-5.png";
import blogImage6 from "../../../../assets/img/blog/blog-6.png";
import blogImage7 from "../../../../assets/img/blog/blog-7.png";
import blogImage8 from "../../../../assets/img/blog/blog-8.png";
import blogImage9 from "../../../../assets/img/blog/blog-9.png";
import BlogItem from "../../../../components/blogItem/blogItem";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";
const HomeBlog = () => {
  const data = [
    {
      img: blogImage1,
      date: "Insight    |    20.Sep.2023",
      heading:
        "Unlocking The Future: 5 Predictions For Growth Marketing In 2024",
      desc: "Dive into the future of growth marketing with our top five predictions for 2024. From AI-driven strategies to the resurgence of organic reach.",
    },
    {
      img: blogImage2,
      date: "Insight    |    20.Sep.2023",
      heading: "Lead Magnets 101: Crafting Irresistible Offers",
      desc: "Learn the art of creating powerful lead magnets that captivate and convert. Dive into examples and best practices to enhance your lead generation.",
    },
    {
      img: blogImage3,
      date: "Insight    |    20.Sep.2023",
      heading:
        "The Power Of Personalization:Tailoring Experiences In Marketing",
      desc: "Explore how personalized marketing can skyrocket engagement and sales. Get insights into tools and techniques for creating tailored.",
    },
    {
      img: blogImage4,
      date: "Insight    |    20.Sep.2023",
      heading: "Beyond Clicks: Metrics That Truly  Matter In Growth Marketing",
      desc: "Shift your focus from vanity metrics to ones that drive real growth. Unravel the key performance indicators that make a difference with LeadBud.io.",
    },
    {
      img: blogImage5,
      date: "Insight    |    20.Sep.2023",
      heading: "Chatbots And Growth: The New Frontier In Customer Engagement",
      desc: "Discover the transformative potential of chatbots  in nurturing leads and boosting conversions. LeadBud.io sheds light on leveraging AI.",
    },
    {
      img: blogImage6,
      date: "Insight    |    20.Sep.2023",
      heading: "The Anatomy Of A High-Converting Landing Page",
      desc: "Craft landing pages that convert! From compelling CTAs to intuitive design, delve into elements that ensure maximum lead capture with LeadBud.io.",
    },
    {
      img: blogImage7,
      date: "Insight    |    20.Sep.2023",
      heading: "Video Marketing: The Unstoppabl Force In Digital Growth",
      desc: "Explore the dynamic world of video marketing and its role in driving unparalleled growth. LeadBud.io shares strategies for captivating visuals.",
    },
    {
      img: blogImage8,
      date: "Insight    |    20.Sep.2023",
      heading: "Email Sequences: Nurturing Leads To Loyal Customers",
      desc: "Uncover the secrets to crafting email sequences that guide leads down the sales funnel, ensuring loyalty and repeat business with insights.",
    },
    {
      img: blogImage9,
      date: "Insight    |    20.Sep.2023",
      heading: "Organic Growth In A Paid World: Strategies That Still Work",
      desc: "Navigate the realm of organic growth strategies that defy the dominance of paid campaigns. LeadBud.io highlights techniques.</",
    },
  ];

  return (
    <div className="home-blogs">
      <div className="container" style={{ overflow: "hidden" }}>
        <div className="search-area">
          <div className="row">
            <div className="col-xl-3 col-lg-10 col-md-12 col-sm-12 col-xs-12 search-bar">
              <input
                type="search"
                placeholder="Search"
                className="form-control"
              />
            </div>

            <div
              className="col-lg-9 col-md-9 blog-nav-container"
              style={{
                overflow: "hidden",
                width: "870px",
                overflowX: "scroll",
              }}
            >
              <ul
                className="nav nav-tabs"
                style={{
                  borderBottom: "hidden",
                  overflow: "hidden",
                  width: "870px",
                }}
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    All
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Growth Strategies
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Tool Insights
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="success-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#success"
                    type="button"
                    role="tab"
                    aria-controls="success"
                    aria-selected="false"
                  >
                    Success Stories
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="industry-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#industry"
                    type="button"
                    role="tab"
                    aria-controls="industry"
                    aria-selected="false"
                  >
                    Industry Trends
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="data-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#data"
                    type="button"
                    role="tab"
                    aria-controls="data"
                    aria-selected="false"
                  >
                    Data Analytics
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="row justify-content-center">
              {data.map((item, i) => (
                <BlogItem
                  key={i}
                  image={item.img}
                  date={item.date}
                  heading={item.heading}
                  desc={item.desc}
                />
              ))}
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="item">
              <h3>Coming Soon</h3>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            {" "}
            <div className="item">
              <h3>Coming Soon</h3>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="success"
            role="tabpanel"
            aria-labelledby="success-tab"
          >
            {" "}
            <div className="item">
              <h3>Coming Soon</h3>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="industry"
            role="tabpanel"
            aria-labelledby="industry-tab"
          >
            {" "}
            <div className="item">
              <h3>Coming Soon</h3>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="data"
            role="tabpanel"
            aria-labelledby="data-tab"
          >
            {" "}
            <div className="item">
              <h3>Coming Soon</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBlog;
