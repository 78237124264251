import React, { useEffect, useState } from "react";
import { monthlyPackage, yearlyPackage } from "./ourPricingData";
import PricingPackage from "../../../../components/pricingPackageComponent/pricingPackage";
import PlanFeatures from "../../../../components/planFeatures/planFeatures";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";

const OurPricing = () => {
  const [checked, setChecked] = useState(false);

  const [bill, setBill] = useState(monthlyPackage);

  const [showTable, setShowTable] = useState(false);

  const handleShowTable = () => {
    console.log("working >>>", showTable);
    if (showTable) {
      return setShowTable(false);
    }
    setShowTable(true);
  };

  const handleOnClick = () => {
    if (checked) {
      return setChecked(false);
    }
    setChecked(true);
  };

  useEffect(() => {
    if (checked) {
      return setBill(yearlyPackage);
    }
    setBill(monthlyPackage);
  }, [checked]);

  return (
    <div className="amp-pricing">
      <div className="container">
        <div className="content-whole">
          <div className="price-container">
            <div className="switch-area">
              <div className="bill-label">
                <p>Bill Monthly</p>
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  onClick={() => {
                    handleOnClick();
                  }}
                  id="toggleSwitch"
                />
                <span className="slider round"></span>
              </label>
              <div className="bill-label">
                <p>Bill Yearly</p>
              </div>
            </div>

            <div className="price" style={{ display: "block" }}>
              <div className="row justify-content-center">
                {bill.map((item, i) => (
                  <PricingPackage
                    key={i}
                    packageName={item.packageName}
                    packageDesc={item.desc}
                    price={item.price}
                    packageInclude={item.package}
                    whatsIcluded={item.whatsIncluded}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="button-area">
          <button
            onClick={() => {
              handleShowTable();
            }}
            className="btn btn-see"
            id="btn-see"
          >
            See all plan features <i className="fa-solid fa-chevron-down"></i>
          </button>
        </div>

        {showTable ? <PlanFeatures /> : null}
      </div>
    </div>
  );
};

export default OurPricing;
