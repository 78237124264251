import React from "react";
import emailFinder from "../../assets/img/pricing/email-finder.png";
import verifyEmail from "../../assets/img/pricing/email-verify.png";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
const PlanFeatures = () => {
  return (
    <div className="pricing-plan">
      <div style={{ overflowX: "auto" }}>
        <table>
          <col style={{ width: "40%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />

          <tr className="first">
            <th className="compare">Compare Plans</th>
            <th>Trial</th>
            <th>Starter</th>
            <th>Pro</th>
            <th>Team</th>
          </tr>
          <tr>
            <td className="email">
              <img src={emailFinder} alt="email-finder" className="img-fluid" />{" "}
              Email Finder
            </td>
          </tr>
          <tr className="bg">
            <td>
              Email Search
              <br />
              <span>Find pre-verified emails by leads</span>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
          </tr>

          <tr>
            <td>
              Bulk Email Verifier
              <br />
              <span>Clean your list from fake, abandoned</span>
            </td>
            <td>10 000</td>
            <td>10 000</td>
            <td>10 000</td>
            <td>10 000</td>
          </tr>

          <tr className="bg">
            <td>
              Email Verifier (Chrome extension)
              <br />
              <span>Verify email addresses right from the page</span>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
          </tr>

          <tr>
            <td>
              Email Verifier (API)
              <br />
              <span>Clean your list from fake, abandoned</span>
            </td>
            <td>10 000</td>
            <td>10 000</td>
            <td>10 000</td>
            <td>10 000</td>
          </tr>

          <tr className="bg">
            <td>
              7-tier verification
              <br />
              <span>Find pre-verified emails by leads</span>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
          </tr>

          <tr>
            <td>
              Greylisting bypass
              <br />
              <span>Clean your list from fake, abandoned</span>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
          </tr>

          <tr>
            <td className="email">
              <img src={verifyEmail} alt="email-verify" className="img-fluid" />{" "}
              Email Verification
            </td>
          </tr>
          <tr className="bg">
            <td>
              Email Search
              <br />
              <span>Find pre-verified emails by leads</span>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
          </tr>

          <tr>
            <td>
              Bulk Email Verifier
              <br />
              <span>Clean your list from fake, abandoned</span>
            </td>
            <td>10 000</td>
            <td>10 000</td>
            <td>10 000</td>
            <td>10 000</td>
          </tr>

          <tr className="bg">
            <td>
              Email Verifier (Chrome extension)
              <br />
              <span>Verify email addresses right from the page</span>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
          </tr>

          <tr>
            <td>
              Email Verifier (API)
              <br />
              <span>Clean your list from fake, abandoned</span>
            </td>
            <td>10 000</td>
            <td>10 000</td>
            <td>10 000</td>
            <td>10 000</td>
          </tr>

          <tr className="bg">
            <td>
              7-tier verification
              <br />
              <span>Find pre-verified emails by leads</span>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
          </tr>

          <tr>
            <td>
              Greylisting bypass
              <br />
              <span>Clean your list from fake, abandoned</span>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-xmark"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
            <td>
              <i className="fa-solid fa-check"></i>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default PlanFeatures;
