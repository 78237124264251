import React from "react";
import dataToRevenue from "../../../../assets/img/data-to-revenue-image.png";
import "../../../../assets/styles/main.css";
import "../../../../assets/styles/responsive.css";

const DataRevenue = () => {
  return (
    <div className="section data-to-revenue">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <div className="content-area">
              <h2 className="aos-item aos-inint aos-animate">
                Turn Data Into Your
                <br />
                #1 Revenue Channel With Leadbud
              </h2>

              <p className="aos-item aos-inint aos-animate">
                We Helped Thousands Of Businesses Scale Their Business To New
                Heights. Leadbud Customers Achieve 340% Growth Overtime With Our
                Marketing Tools
              </p>
            </div>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <div className="image-area aos-item aos-inint aos-animate">
              <img
                src={dataToRevenue}
                className="img-fluid"
                alt="data-to-revenue"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataRevenue;
